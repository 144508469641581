import { useEffect, useState } from 'react'

import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useSplitTreatments } from '@splitsoftware/splitio-react'
import Cookies from 'js-cookie'
import Image from 'next/image'
import Link from 'next/link'

import { Button } from '@/stories/buttons/button'
import Drawer from '@/stories/drawer'
import { breakpoint } from '@/theme'
import { TEST_IDS } from '@components/modal/test/constants'
import { track } from '@helpers/analytics'
import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS } from '@helpers/constants'
import { FrownIcon, PeaceIcon } from '@microcomponents/icons/new'
import { LogoShape } from '@microcomponents/shapes/logo'

import { ButtonContainer, LinkContainer, Modal, Paragraph, Title } from './style'

interface AgeGateProps {
  setCookie: (value: { hasVerifiedAge: boolean }) => void
}

export default function AgeGate({ setCookie }: AgeGateProps) {
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const anonId = Cookies.getJSON('ajs_anonymous_id')

  const { treatments, isReady } = useSplitTreatments({
    names: [SPLIT_EXPERIMENTS.REDUCE_AGE_GATE_SIZE],
    splitKey: anonId
  })

  const reduceModalSize =
    isReady && treatments[SPLIT_EXPERIMENTS.REDUCE_AGE_GATE_SIZE].treatment === SPLIT_TREATMENTS.REDUCE_AGE_GATE_SIZE.ON

  const handleConfirm = () => {
    setCookie({ hasVerifiedAge: true })
    track('Modal.AgeGate.Confirm')
  }

  const handleDismiss = () => {
    track('Modal.AgeGate.Dismiss')
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true)
      track('Modal.AgeGate.View')
    }, 2000)
    // cleanup on unmount
    return () => clearTimeout(timer)
  }, [])

  return (
    <Drawer
      allowClose={false}
      modalElementClass={reduceModalSize ? reducedDrawerCss : drawerCss}
      onRequestClose={() => {
        // Do Nothing (Comment needed to silence linting)
      }}
      open={open}>
      <StyledModal reduceModalSize={reduceModalSize} data-e2eid={TEST_IDS.COMPONENT}>
        <div>
          <LogoShape />
          <Title>{`Are you 21 years or older?`}</Title>
          <SlimParagraph>{`We need this information for legal stuff.`}</SlimParagraph>
          {!reduceModalSize && (
            <ImageContainer>
              <Image src="/static/images/dino.png" width="110" height="97" />
            </ImageContainer>
          )}
          <ButtonContainer>
            <Button
              variant="variant-2"
              backgroundColor={theme.colors.button}
              icon={<PeaceIcon />}
              onClick={handleConfirm}
              data-e2eid={TEST_IDS.VERIFICATION_BUTTON}
              width="100%">
              Yes
            </Button>
          </ButtonContainer>
          <LinkContainer>
            <Link
              data-e2eid={TEST_IDS.GOOGLE_LINK}
              onClick={handleDismiss}
              href="https://google.com"
              rel="noopener noreferrer">
              <Button variant="variant-2" icon={<FrownIcon />} width="100%">
                No
              </Button>
            </Link>
          </LinkContainer>
        </div>
      </StyledModal>
    </Drawer>
  )
}

const reducedDrawerCss = css`
  text-align: center;

  @media (max-width: ${breakpoint.max.lg}px) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`

const drawerCss = css`
  ${reducedDrawerCss}
  height: 100%;
  position: absolute;
  outline: none;
  top: 3rem;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
`

const StyledModal = styled(Modal)<{ reduceModalSize: boolean }>`
  max-width: none;
  @media (max-width: ${breakpoint.max.lg}px) {
    border-radius: 0;
  }
  ${({ reduceModalSize }) =>
    !reduceModalSize &&
    `
      border-radius: 0;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
  `}
`

const ImageContainer = styled.div`
  margin: ${(props) => props.theme.spacing.larger}px 0;
`

const SlimParagraph = styled(Paragraph)`
  max-width: 250px;
  margin: auto;
`
